/* src/components/SolverOverlay.css */

/* Floating Action Button */
.fab-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 56px;
    height: 56px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 999;
    transition: transform 0.3s ease;
    animation: pulse 2s infinite;
}

    .fab-button:hover {
        background-color: #0056b3;
    }

    .fab-button.open {
        transform: rotate(45deg);
    }

/* Solver Card Overlay */
.solver-card {
    position: absolute;
    bottom: 90px;
    left: 20px;
    width: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 999;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
}

    .solver-card.open {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }

.solver-card-content {
    padding: 20px;
}

    .solver-card-content h2 {
        margin-top: 0;
    }

.solver-button {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 12px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: box-shadow 0.2s ease;
    color: white;
    font-weight: bold;
    border: none;
}

    .solver-button:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

/* Different Button Styles */
.solve-manually {
    background-color: #28a745;
}

.system-suggestion {
    background-color: #ffc107;
    color: black;
}

.ai-solver {
    background: linear-gradient(45deg, #6a11cb, #2575fc); /* Gradient background */
}

/* SweetAlert2 Styles */
.swal-popup {
    font-family: 'Arial', sans-serif;
}

.swal-title {
    font-weight: bold;
}

.swal-confirm {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}
