/* src/components/BerthAssignmentMenu.css */

.berth-assignment-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 2000;
    width: 200px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ccc;
}

    .menu-header button {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
    }

.berth-assignment-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.berth-assignment-menu li {
    border-bottom: 1px solid #eee;
}

    .berth-assignment-menu li:last-child {
        border-bottom: none;
    }

.berth-assignment-menu button {
    width: 100%;
    padding: 8px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
}

    .berth-assignment-menu button:hover {
        background-color: #f0f0f0;
    }

    .berth-assignment-menu button:disabled {
        color: #999;
        cursor: not-allowed;
    }
